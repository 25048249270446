import Axios from "axios";

const axiosInstance = Axios.create({
  baseURL: "https://ainetworkcloud.com/api",
  // baseURL: "http://localhost:4000/api",
  //baseURL: "http://54.148.148.123/api",
  withCredentials: true,
});

export default axiosInstance;
