/* eslint-disable */

import React, { Fragment, useEffect, useState } from "react";
import { Col, ListGroup, Row, Spinner } from "react-bootstrap";
import GaugeComponent from "react-gauge-component";
// import { useNavigate } from 'react-router-dom';
import DHSNetworkMapImg from "assets/img/dhs-network-map.jpg";
import FalconLightBox from "components/common/FalconLightBox";
import axiosInstance from "services/axiosInstance";
import GaugeChart from "react-gauge-chart";

const NetworkMaps = ({ loading, vlanData, customerPerf }) => {
  const [data, setData] = useState(vlanData);
  const [vlanPerformanceData, setVlanPerformanceData] = useState();

  console.log({ customerPerf });

  // useEffect(() => {
  //   axiosInstance.get("/cloud-work-data/DHS Network").then((res) => {
  //     setData(res.data.result);
  //   });
  // }, []);

  // useEffect(() => {
  //   const myFunction = () => {
  //     // if (data && data.length > 0) {
  //     //   const _data = data.map(da => {
  //     //     return {
  //     //       ...da,
  //     //       value:
  //     //         da.type === 'performance' || da.type === 'throughput'
  //     //           ? Math.floor(Math.random() * (+da.value + 10 - +da.value + 1)) +
  //     //             +da.value
  //     //           : // : da.type === 'tatency'
  //     //             // ? Math.floor(Math.random() * +da.value) + 2
  //     //             +da.value
  //     //     };
  //     //   });

  //     //   // console.log(_data);
  //     //   setData(_data);
  //     // }

  //     axiosInstance.get("/cloud-work-data/DHS Network").then((res) => {
  //       setData(res.data.result);
  //     });
  //   };

  //   const intervalId = setInterval(myFunction, 8000);

  //   return () => clearInterval(intervalId);
  // }, [data]);

  const kbitsToMbits = (value) => {
    if (value >= 1000) {
      value = value / 1000;
      if (Number.isInteger(value)) {
        return value.toFixed(0) + " GBs";
      } else {
        return value.toFixed(1) + " GBs";
      }
    } else {
      return value.toFixed(0) + " GBs";
    }
  };

  const formatPerf = (value) => {
    // value.toString().replace(/0/g, '');
    // if (value >= 100) {
    //   value = value / 100;
    //   if (Number.isInteger(value)) {
    //     return value.toFixed(0) + ' Gbs';
    //   } else {
    //     return value.toFixed(1) + ' Gbs';
    //   }
    // } else {
    return value.toFixed(0) + " Gbs";
    //}
  };

  const latencyMeasurement = (value) => {
    return value + " ms";
  };

  const formatAvailability = (value) => {
    var number = value;
    var formattedNumber = parseFloat(number.toFixed(3)) + "%";
    return formattedNumber;
  };

  // useEffect(() => {
  //   axiosInstance.get(`/customers/performance/${4}`).then((res) => {
  //     // setVlanPerformanceData(res.data.vlanPerformance);

  //     setVlanPerformanceData(res.data);
  //   });
  // }, []);

  // const maxPerf = 1000;
  // const totPerf = parseFloat(vlanPerformanceData.performance.tot_perf);
  // const percentage = totPerf / maxPerf;

  return (
    <Fragment>
      <Row className="g-3 mb-3 mt-4">
        <h5>Performance</h5>
        {loading
          ? [1, 2, 3, 4].map((_, index) => {
              return (
                <Col md={3} key={index}>
                  <Spinner className="start-50" animation="grow" />
                </Col>
              );
            })
          : customerPerf &&
            customerPerf.length > 0 &&
            customerPerf.map((v, index) => {
              return (
                <Col md={3} key={index}>
                  <h6 className="mb-3 mt-2 text-center">{v.name}</h6>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ["#5BE12C", "#F5CD19", "#EA4228"],
                      width: 0.3,
                      padding: 0.003,
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                        formatTextValue: formatPerf,
                      },
                      tickLabels: {
                        type: "outer",
                        ticks: [
                          { value: 0 },
                          { value: 10 },
                          { value: 20 },
                          { value: 30 },
                          { value: 40 },
                          { value: 50 },
                          { value: 60 },
                          { value: 70 },
                          { value: 80 },
                        ],
                        valueConfig: {
                          formatTextValue: formatPerf,
                        },
                      },
                    }}
                    value={+v.perf}
                    maxValue={80}
                    pointer={{ type: "arrow", elastic: true }}
                  />
                </Col>
              );
            })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Throughput</h5>
        {loading
          ? [1, 2, 3, 4].map((_, index) => {
              return (
                <Col md={3} key={index}>
                  <Spinner className="start-50" animation="grow" />
                </Col>
              );
            })
          : customerPerf &&
            customerPerf.length > 0 &&
            customerPerf.map((v, index) => {
              return (
                <Col md={3} key={index}>
                  <h6 className="mb-3 mt-2 text-center">{v.name}</h6>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ["#5BE12C", "#F5CD19", "#EA4228"],
                      width: 0.3,
                      padding: 0.003,
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                        formatTextValue: kbitsToMbits,
                      },
                      tickLabels: {
                        type: "outer",
                        ticks: [
                          { value: 0 },
                          { value: 5 },
                          { value: 10 },
                          { value: 20 },
                          { value: 30 },
                          { value: 40 },
                          { value: 50 },
                          { value: 60 },
                          { value: 70 },
                        ],
                        valueConfig: {
                          formatTextValue: kbitsToMbits,
                        },
                      },
                    }}
                    value={+v.throughput}
                    maxValue={70}
                    pointer={{ type: "arrow", elastic: true }}
                  />
                </Col>
              );
            })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Latency</h5>
        {loading
          ? [1, 2, 3, 4].map((_, index) => {
              return (
                <Col md={3} key={index}>
                  <Spinner className="start-50" animation="grow" />
                </Col>
              );
            })
          : customerPerf &&
            customerPerf.length > 0 &&
            customerPerf.map((v, index) => {
              return (
                <Col md={3} key={index}>
                  <h6 className="mb-3 mt-2 text-center">{v.name}</h6>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ["#5BE12C", "#F5CD19", "#EA4228"],
                      width: 0.3,
                      padding: 0.003,
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                        formatTextValue: latencyMeasurement,
                      },
                      tickLabels: {
                        type: "outer",
                        ticks: [
                          { value: 10 },
                          { value: 20 },
                          { value: 40 },
                          { value: 60 },
                          { value: 70 },
                        ],
                        valueConfig: {
                          formatTextValue: latencyMeasurement,
                        },
                      },
                    }}
                    value={+v.max_latency}
                    maxValue={70}
                    pointer={{ type: "arrow", elastic: true }}
                  />
                </Col>
              );
            })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Availability</h5>
        {loading
          ? [1, 2, 3, 4].map((_, index) => {
              return (
                <Col md={3} key={index}>
                  <Spinner className="start-50" animation="grow" />
                </Col>
              );
            })
          : customerPerf &&
            customerPerf.length > 0 &&
            customerPerf.map((v, index) => {
              return (
                <Col md={3} key={index}>
                  <h6 className="mb-3 mt-2 text-center">{v.name}</h6>
                  <GaugeComponent
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ["#EA4228", "#F5CD19", "#5BE12C"],
                      width: 0.3,
                      padding: 0.003,
                      subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}],
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                      },
                      tickLabels: {
                        type: "outer",
                        ticks: [
                          { value: 70 },
                          { value: 80 },
                          { value: 95 },
                          { value: 96 },
                          { value: 97 },
                          { value: 98 },
                          { value: 99 },
                          { value: 100 },
                        ],
                        valueConfig: {},
                      },
                    }}
                    value={Math.floor(v.availability * 100) / 100}
                    minValue={70}
                    maxValue={100}
                  />
                </Col>
              );
            })}
      </Row>
    </Fragment>
  );
};

export default NetworkMaps;
