import PropTypes from "prop-types";
import classNames from "classnames";
import { Collapse, Nav } from "react-bootstrap";
import axiosInstance from "services/axiosInstance";
import React, { useEffect, useState } from "react";
import { useAppContext } from "providers/AppProvider";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// CollapseItems Component to handle collapsible menus
const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();

  // Function to determine if the collapse should be open
  const openCollapse = (children) => {
    const checkLink = (child) => {
      if (child.to === pathname) {
        return true;
      }
      return (
        child.children && child.children.some(checkLink) // Check nested children
      );
    };
    return children.some(checkLink);
  };

  // State to track whether the collapse is open or not
  const [open, setOpen] = useState(openCollapse(route.children));

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => setOpen(!open)}
        className={classNames("dropdown-indicator cursor-pointer", {
          "text-500": !route.active,
        })}
        aria-expanded={open}
      >
        <NavbarVerticalMenuItem route={route} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav" as="ul">
          {route.children.map((childRoute) => (
            <NavbarVerticalMenu key={childRoute.name} routes={[childRoute]} />
          ))}
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
  }).isRequired,
};

// NavbarVerticalMenu Component for rendering the entire menu structure
const NavbarVerticalMenu = ({ routes }) => {
  const {
    config: { showBurgerMenu },
    setConfig,
  } = useAppContext();
  let navigate = useNavigate();

  // Handle navigation and optionally toggle the burger menu
  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig("showBurgerMenu", !showBurgerMenu);
    }
  };

  // Render the routes dynamically
  return routes.map((route) => {
    if (!route.children) {
      return (
        <Nav.Item as="li" key={route.name} onClick={handleNavItemClick}>
          <NavLink
            end={route.exact}
            to={route.to}
            // onClick={(e) => {
            //   e.preventDefault(); // Prevent the default behavior
            //   navigate(`app/${route.name.toLowerCase().replaceAll(" ", "-")}`);
            // }}
            className={({ isActive }) =>
              isActive && route.to !== "#!" ? "active nav-link" : "nav-link"
            }
          >
            <NavbarVerticalMenuItem route={route} />
          </NavLink>
        </Nav.Item>
      );
    }

    return <CollapseItems route={route} key={route.name} />;
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
};

export default NavbarVerticalMenu;
