import React, { Fragment, useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import { Col, Row, Spinner } from "react-bootstrap";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axiosInstance from "services/axiosInstance";
import Equinix from "../customer-multi-clouds/NetworkMaps";

const lineLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "DEC",
];
function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + "M";
  }
  return num.toString();
}
const EquinixAinc = () => {
  const [, setClouds] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [customerDataStatus, setCustomerDataStatus] = useState("idle");
  const [lineData, setLineData] = useState({
    labels: lineLabels,
    datasets: [],
  });
  const [lineDataStatus, setLineDataStatus] = useState("idle");
  const [costPerCSP, setCostPerCSP] = useState(null);
  const [costPerCSPStatus, setCostPerCSPStatus] = useState("idle");
  const [costPerService, setCostPerService] = useState(null);
  const [costPerServiceStatus, setCostPerServiceStatus] = useState("idle");

  useEffect(() => {
    axiosInstance.get("/cloud-costs").then((res) => {
      setClouds(res.data.cloudsWithCosts);
    });
  }, []);

  useEffect(() => {
    setCustomerDataStatus("fetching");
    axiosInstance
      .get("/customer-costs/DHS Network")
      .then((res) => {
        setCustomerData(res.data.result);
      })
      .finally(() => {
        setCustomerDataStatus("idle");
      });
  }, []);

  useEffect(() => {
    setLineDataStatus("fetching");
    axiosInstance
      .get("/customer-costs/DHS Network/by-month/")
      .then((res) => {
        const _lineData = {
          labels: lineLabels,
          datasets: res.data.result,
        };
        setLineData(_lineData);
      })
      .finally(() => {
        setLineDataStatus("idle");
      });
  }, []);

  useEffect(() => {
    setCostPerCSPStatus("fetching");
    axiosInstance
      .get("/customer-costs/DHS Network/per-csp")
      .then((res) => {
        setCostPerCSP(res.data.result);
      })
      .finally(() => {
        setCostPerCSPStatus("idle");
      });
  }, []);

  useEffect(() => {
    setCostPerServiceStatus("fetching");
    axiosInstance
      .get("/customer-costs/DHS Network/per-service")
      .then((res) => {
        setCostPerService(res.data.result);
      })
      .finally(() => {
        setCostPerServiceStatus("idle");
      });
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: "#fff",
          font: {
            family: "poppins",
          },
        },
      },
      datalabels: {
        formatter: function (value) {
          return "$" + Math.floor(value / 1000); //for number format
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 12,
          family: "poppins",
        },
      },
    },
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#fff",
        },
      },
      title: {
        display: true,
        text: "Cost Per Cloud $K",
        color: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(255, 255, 255, 0.5)",
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.5)",
        },
        ticks: {
          color: "white", // Change color of y axis labels to white
        },
      },
    },
  };

  return (
    <Fragment>
      <h4 style={{ marginTop: "8px" }}>DHS Network</h4>
      <Row className="g-3 mb-3 mt-4">
        {customerDataStatus === "fetching" ? (
          <Col md={3} xxl={3} mt={4}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          </Col>
        ) : (
          customerData.map((customer) => {
            return (
              <Col md={3} xxl={3} mt={4} key={customer.provider_name}>
                <h5 className="mb-2 mt-2 text-center">
                  {customer.provider_name} Cost Details <br />$
                  {
                    +(customer.total_cost / 1000).toFixed(
                      +customer.total_cost > 10000 ? 1 : 2
                    )
                  }
                  K
                </h5>
                <Doughnut
                  data={customer.data}
                  plugins={[ChartDataLabels]}
                  options={options}
                />
              </Col>
            );
          })
        )}
      </Row>
      <Row>
        <Col md={6}>
          {lineDataStatus === "fetching" ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          ) : (
            <Line options={lineOptions} data={lineData} />
          )}
        </Col>
        {costPerCSPStatus === "fetching" ? (
          <Col md={3} xxl={3} mt={4}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          </Col>
        ) : (
          costPerCSP !== null && (
            <Col md={3} xxl={3} mt={4}>
              <h5 className="mt-2 text-center">
                Cost Per CSP <br />$
                {/* {
                  +(costPerCSP.totalSum / 1000).toFixed(
                    +costPerCSP.totalSum > 10000 ? 1 : 2
                  )
                }
                K */}
                {formatNumber(costPerCSP.totalSum)}
              </h5>
              <Doughnut
                data={costPerCSP}
                plugins={[ChartDataLabels]}
                options={options}
              />
            </Col>
          )
        )}
        {costPerServiceStatus === "fetching" ? (
          <Col md={3} xxl={3} mt={4}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          </Col>
        ) : (
          costPerService !== null && (
            <Col md={3} xxl={3} mt={4}>
              <h5 className="mt-2 text-center">
                Cost Per Service <br />${formatNumber(costPerService.totalSum)}
              </h5>
              <Doughnut
                data={costPerService}
                plugins={[ChartDataLabels]}
                options={options}
              />
            </Col>
          )
        )}
      </Row>
      <Equinix />
    </Fragment>
  );
};

export default EquinixAinc;
