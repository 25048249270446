import { useEffect, useState } from "react";
import NetworkMaps from "../customer-multi-clouds/NetworkMaps";
import { useParams } from "react-router-dom";
import axiosInstance from "services/axiosInstance";
import axios from "axios";

const DataCenter = () => {
  const { customer, data_center } = useParams();
  const [loading, setLoading] = useState(false);
  const [customerPerf, setCustomerPerf] = useState([]);
  const [vlanPerformanceData, setVlanPerformanceData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/customers/performance/${customer}`)
      .then((res) => {
        setVlanPerformanceData(res.data);
      })
      .finally(() => {
        setLoading(false);
      });

    axiosInstance
      .get(`/customers/performance/${data_center}/location`)
      .then((res) => {
        setCustomerPerf(res.data.vlanPerformance);
      });
  }, [customer, data_center]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const interval = setInterval(() => {
      if (data_center) {
        axiosInstance
          .get(`/customers/performance/${data_center}/location`, {
            signal,
          })
          .then((res) => {
            setCustomerPerf(res.data.vlanPerformance);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request canceled", err.message);
            } else {
              console.error("Other error", err);
            }
          });
      }
    }, 5000);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [data_center, loading]);

  console.log({ vlanPerformanceData });

  return (
    <NetworkMaps
      loading={loading}
      vlanData={vlanPerformanceData}
      customerPerf={customerPerf}
    />
  );
};

export default DataCenter;
