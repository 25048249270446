/* eslint-disable */
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Doughnut, Line } from "react-chartjs-2";
import axiosInstance from "services/axiosInstance";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Col, ListGroup, Row, Spinner } from "react-bootstrap";
import React, { Fragment, useEffect, useRef, useState } from "react";
import GaugeComponent from "react-gauge-component";
import FalconLightBox from "components/common/FalconLightBox";
import DODNetworkMapImg from "assets/img/dod-network-map.jpg";
import DOENetworkMapImg from "assets/img/doe-network-map.jpg";
import DHSNetworkMapImg from "assets/img/dhs-network-map.jpg";
import NASANetworkMapImg from "assets/img/nasa-network-map.jpg";

const lineLabels = ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "DEC"];

const CustomerDetails = () => {
  const { customer } = useParams();
  const [vlanPerformanceData, setVlanPerformanceData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [customerDataStatus, setCustomerDataStatus] = useState("idle");
  const [lineData, setLineData] = useState({
    labels: lineLabels,
    datasets: [],
  });
  const [lineDataStatus, setLineDataStatus] = useState("idle");
  const [costPerCSP, setCostPerCSP] = useState({});
  const [costPerDCStatus, setCostPerDCStatus] = useState("idle");
  const [costPerServiceStatus, setCostPerServiceStatus] = useState("idle");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerPerf, setCustomerPerf] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupedSums, setGroupedSums] = useState(null);
  const [perDCCost, setPerDCCost] = useState({});
  const [perServiceCost, setPerServiceCost] = useState({});
  const [totalCostDC, setTotalCostDC] = useState(0);
  const [totalServiceCost, setTotalServiceCost] = useState(0);
  const [summedService, setSummedService] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const hasNavigated = useRef(false); // Tracks whether navigation has occurred

  const CancelToken = axios.CancelToken;
  let cancel;

  useEffect(() => {
    const timer = setTimeout(() => {
      hasNavigated.current = true;
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (hasNavigated.current) {
      // If there is an ongoing request, cancel it
      if (cancel) {
        cancel();
      }

      axiosInstance
        .get(`/customers/${customer}?execute=stop`, {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          }),
        })
        .then((res) => {
          setSummedService(res.data.vlanPerformance);
        })
        .catch((err) => {
          // Handle cancellation or other errors
          if (axios.isCancel(err)) {
            console.log("Request canceled", err.message);
          } else {
            console.error("Error fetching data", err);
          }
        });

      return () => {
        if (cancel) {
          cancel();
        }
      };
    } else {
      console.log("off");
    }
    return () => {
      console.log("Cleanup on route change");
    };
  }, [location.pathname]);

  useEffect(() => {
    axiosInstance.get(`/data-centers/cost/all/${customer}`).then((res) => {
      setSummedService(res.data.vlanPerformance);
    });
  }, [customer]);

  useEffect(() => {
    setCostPerDCStatus("fetching");
    setCostPerServiceStatus("fetching");

    axiosInstance.get(`/data-centers/${customer}`).then((res) => {
      setGroupedSums(res.data.groupedSums);
      setCostPerCSP(res.data.resp);
      const _lineData = {
        labels: lineLabels,
        datasets: res.data.datasetsForLineChart,
      };
      setLineData(_lineData);
    });

    axiosInstance
      .get(`/data-centers/cost/${customer}`)
      .then((res) => {
        setPerDCCost(res.data.transform);
        setTotalCostDC(res.data.sum);
      })
      .finally(() => {
        setCostPerDCStatus("idle");
      });

    axiosInstance
      .get(`/data-centers/cost/service/${customer}`)
      .then((res) => {
        setPerServiceCost(res.data.transform);
        setTotalServiceCost(res.data.sum);
      })
      .finally(() => {
        setCostPerServiceStatus("idle");
      });
  }, [customer]);

  useEffect(() => {
    const fetchData = () => {
      // setCostPerDCStatus("fetching");
      // setCostPerServiceStatus("fetching");

      axiosInstance.get(`/data-centers/${customer}`).then((res) => {
        setGroupedSums(res.data.groupedSums);
        setCostPerCSP(res.data.resp);
        const _lineData = {
          labels: lineLabels,
          datasets: res.data.datasetsForLineChart,
        };
        setLineData(_lineData);
      });

      axiosInstance
        .get(`/data-centers/cost/${customer}`)
        .then((res) => {
          setPerDCCost(res.data.transform);
          setTotalCostDC(res.data.sum);
        })
        .finally(() => {
          setCostPerDCStatus("idle");
        });

      axiosInstance
        .get(`/data-centers/cost/service/${customer}`)
        .then((res) => {
          setPerServiceCost(res.data.transform);
          setTotalServiceCost(res.data.sum);
        })
        .finally(() => {
          setCostPerServiceStatus("idle");
        });

      axiosInstance.get(`/data-centers/cost/all/${customer}`).then((res) => {
        setSummedService(res.data.vlanPerformance);
      });
    };

    // Fetch data immediately
    fetchData();

    // Set up an interval to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [customer]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const interval = setInterval(() => {
      if (selectedLocation && !loading) {
        axiosInstance
          .get(`/customers/performance/${selectedLocation}/location`, {
            signal,
          })
          .then((res) => {
            setCustomerPerf(res.data.vlanPerformance);
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Request canceled", err.message);
            } else {
              console.error("Other error", err);
            }
          });
      }
    }, 5000);

    return () => {
      clearInterval(interval);
      controller.abort();
    };
  }, [selectedLocation, loading]);

  useEffect(() => {
    axiosInstance.get(`/customers/performance/${customer}`).then((res) => {
      setVlanPerformanceData(res.data);
    });

    axiosInstance.get(`/customers/${customer}`).then((res) => {
      setSelectedCustomer(res.data.customer);
    });

    setCustomerPerf([]);
    setSelectedLocation(null);
  }, [customer]);

  useEffect(() => {
    setCustomerDataStatus("fetching");
    axiosInstance
      .get(`/customer-costs/${transformString(customer)}`)
      .then((res) => {
        setCustomerData(res.data.result);
      })
      .finally(() => {
        setCustomerDataStatus("idle");
      });
  }, [customer]);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "right",
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: "#fff",
          font: {
            family: "poppins",
          },
        },
      },
      datalabels: {
        formatter: function (value) {
          return "$" + Math.floor(value / 1000); //for number format
        },
        color: "#fff",
        font: {
          weight: "bold",
          size: 12,
          family: "poppins",
        },
      },
    },
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#fff",
        },
      },
      title: {
        display: true,
        text: "Cost Per Data Center $K",
        color: "#fff",
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(255, 255, 255, 0.5)",
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        grid: {
          color: "rgba(255, 255, 255, 0.5)",
        },
        ticks: {
          color: "white", // Change color of y axis labels to white
        },
      },
    },
  };

  const navigateToDC = (location) => {
    navigate(`/app/customers/${customer}/data-centers/${location.id}`);
  };

  const transformString = (str) => {
    const segments = str.split(/[- ]+/);

    // Capitalize the first segment fully
    const firstSegment = segments[0].toUpperCase();

    // Capitalize the first letter of subsequent segments
    const transformedSegments = segments
      .slice(1)
      .map(
        (segment) =>
          segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase()
      );

    // Join the segments back into a single string with spaces
    return [firstSegment, ...transformedSegments].join(" ");
  };

  const formatPerf = (value) => {
    // value.toString().replace(/0/g, '');
    // if (value >= 100) {
    //   value = value / 100;
    //   if (Number.isInteger(value)) {
    //     return value.toFixed(0) + ' Gbs';
    //   } else {
    //     return value.toFixed(1) + ' Gbs';
    //   }
    // } else {
    return value.toFixed(0) + " Gbs";
    //}
  };

  const latencyMeasurement = (value) => {
    return value + " ms";
  };

  const formatThroughput = (value) => {
    return value.toFixed(0) + " GBs";
  };

  return (
    <Fragment>
      <h4 style={{ marginTop: "8px" }}>{transformString(customer)}</h4>
      <Row className="g-3 mb-3 mt-4">
        {customerDataStatus === "fetching" ? (
          <Col md={3} xxl={3} mt={4}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          </Col>
        ) : (
          selectedCustomer?.location?.map((location) => {
            return (
              <Col md={3} xxl={3} mt={4} key={location.id}>
                <h5
                  className="mb-2 mt-2 text-center"
                  style={{ cursor: "pointer" }}
                  onClick={() => navigateToDC(location)}
                >
                  {location?.name} Cost Details <br />$
                  {location?.name !== "" &&
                    groupedSums && // Ensure groupedSums is not null or undefined
                    Object.keys(groupedSums).length > 0 &&
                    new Intl.NumberFormat("en-US", {
                      notation: "compact",
                    }).format(groupedSums[location.name])}
                </h5>

                {typeof costPerCSP !== "undefined" &&
                  costPerCSP.hasOwnProperty(location.name) && (
                    <Doughnut
                      data={costPerCSP[location.name]}
                      plugins={[ChartDataLabels]}
                      options={options}
                    />
                  )}
              </Col>
            );
          })
        )}
      </Row>
      <Row>
        <Col md={6}>
          {lineDataStatus === "fetching" ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          ) : (
            <Line options={lineOptions} data={lineData} />
          )}
        </Col>
        {costPerDCStatus === "fetching" ? (
          <Col md={3} xxl={3} mt={4}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          </Col>
        ) : (
          <Col md={3} xxl={3} mt={4}>
            <h5 className="mt-2 text-center">
              Cost Per Data Center <br />${" "}
              {new Intl.NumberFormat("en-US", {
                notation: "compact",
              }).format(totalCostDC)}
            </h5>
            {Object.values(perDCCost)?.length > 0 && (
              <Doughnut
                data={perDCCost}
                plugins={[ChartDataLabels]}
                options={options}
              />
            )}
          </Col>
        )}
        {costPerServiceStatus === "fetching" ? (
          <Col md={3} xxl={3} mt={4}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
              <Spinner className="start-50" animation="grow" />
            </div>
          </Col>
        ) : (
          <Col md={3} xxl={3} mt={4}>
            <h5 className="mt-2 text-center">
              Cost Per Service <br />${" "}
              {new Intl.NumberFormat("en-US", {
                notation: "compact",
              }).format(totalServiceCost)}
            </h5>
            {Object.values(perServiceCost)?.length > 0 && (
              <Doughnut
                data={perServiceCost}
                plugins={[ChartDataLabels]}
                options={options}
              />
            )}
          </Col>
        )}
      </Row>

      <Row className="g-3 mb-3 mt-4">
        <h5>Performance</h5>
        {summedService &&
          summedService.length > 0 &&
          summedService.map((v, index) => {
            return (
              <Col md={3} key={index}>
                <h6 className="mb-3 mt-2 text-center">{v.serviceName}</h6>
                <GaugeComponent
                  type="semicircle"
                  arc={{
                    nbSubArcs: 150,
                    colorArray: ["#5BE12C", "#F5CD19", "#EA4228"],
                    width: 0.3,
                    padding: 0.003,
                  }}
                  labels={{
                    valueLabel: {
                      fontSize: 40,
                      formatTextValue: formatPerf,
                    },
                    tickLabels: {
                      type: "outer",
                      ticks: [
                        { value: 0 },
                        { value: 20 },
                        { value: 50 },
                        { value: 100 },
                        { value: 150 },
                        { value: 200 },
                        { value: 250 },
                        { value: 300 },
                        { value: 350 },
                        { value: 400 },
                      ],
                      valueConfig: {
                        formatTextValue: formatPerf,
                      },
                    },
                  }}
                  value={+v.totalPerf}
                  maxValue={400}
                  pointer={{ type: "arrow", elastic: true }}
                />
              </Col>
            );
          })}
      </Row>

      <Row className="g-3 mb-3 mt-4">
        <h5>Throughput</h5>
        {summedService &&
          summedService.length > 0 &&
          summedService.map((v, index) => {
            return (
              <Col md={3} key={index}>
                <h6 className="mb-3 mt-2 text-center">{v.serviceName}</h6>
                <GaugeComponent
                  type="semicircle"
                  arc={{
                    nbSubArcs: 150,
                    colorArray: ["#5BE12C", "#F5CD19", "#EA4228"],
                    width: 0.3,
                    padding: 0.003,
                  }}
                  labels={{
                    valueLabel: {
                      fontSize: 40,
                      formatTextValue: formatThroughput,
                    },
                    tickLabels: {
                      type: "outer",
                      ticks: [
                        { value: 0 },
                        { value: 10 },
                        { value: 20 },
                        { value: 30 },
                        { value: 40 },
                        { value: 50 },
                        { value: 60 },
                        { value: 70 },
                        { value: 80 },
                      ],
                      valueConfig: {
                        formatTextValue: formatThroughput,
                      },
                    },
                  }}
                  value={+v.totalThroughput}
                  maxValue={80}
                  pointer={{ type: "arrow", elastic: true }}
                />
              </Col>
            );
          })}
      </Row>

      <Row className="g-3 mb-3 mt-4">
        <h5>Latency</h5>
        {summedService &&
          summedService.length > 0 &&
          summedService.map((v, index) => {
            return (
              <Col md={3} key={index}>
                <h6 className="mb-3 mt-2 text-center">{v.serviceName}</h6>
                <GaugeComponent
                  type="semicircle"
                  arc={{
                    nbSubArcs: 150,
                    colorArray: ["#5BE12C", "#F5CD19", "#EA4228"],
                    width: 0.3,
                    padding: 0.003,
                  }}
                  labels={{
                    valueLabel: {
                      fontSize: 40,
                      formatTextValue: latencyMeasurement,
                    },
                    tickLabels: {
                      type: "outer",
                      ticks: [
                        { value: 10 },
                        { value: 20 },
                        { value: 40 },
                        { value: 60 },
                        { value: 70 },
                      ],
                      valueConfig: {
                        formatTextValue: latencyMeasurement,
                      },
                    },
                  }}
                  value={+v.totalThroughput}
                  maxValue={70}
                  pointer={{ type: "arrow", elastic: true }}
                />
              </Col>
            );
          })}
      </Row>

      <Row className="g-3 mb-3 mt-4">
        <h5>Availability</h5>
        {summedService &&
          summedService.length > 0 &&
          summedService.map((v, index) => {
            return (
              <Col md={3} key={index}>
                <h6 className="mb-3 mt-2 text-center">{v.serviceName}</h6>
                <GaugeComponent
                  arc={{
                    nbSubArcs: 150,
                    colorArray: ["#EA4228", "#F5CD19", "#5BE12C"],
                    width: 0.3,
                    padding: 0.003,
                    subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}],
                  }}
                  labels={{
                    valueLabel: {
                      fontSize: 40,
                    },
                    tickLabels: {
                      type: "outer",
                      ticks: [
                        { value: 70 },
                        { value: 80 },
                        { value: 95 },
                        { value: 96 },
                        { value: 97 },
                        { value: 98 },
                        { value: 99 },
                        { value: 100 },
                      ],
                      valueConfig: {},
                    },
                  }}
                  value={Math.floor(v.totalAvailability * 100) / 100}
                  minValue={70}
                  maxValue={100}
                />
              </Col>
            );
          })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <Col md={6}>
          <FalconLightBox
            image={
              customer === "dod-network"
                ? DODNetworkMapImg
                : customer === "doe-network"
                ? DOENetworkMapImg
                : customer === "dhs-network"
                ? DHSNetworkMapImg
                : NASANetworkMapImg
            }
          >
            <img
              src={
                customer === "dod-network"
                  ? DODNetworkMapImg
                  : customer === "doe-network"
                  ? DOENetworkMapImg
                  : customer === "dhs-network"
                  ? DHSNetworkMapImg
                  : NASANetworkMapImg
              }
              className="img-fluid rounded mb-2 cursor-pointer "
            />
          </FalconLightBox>
        </Col>
        <Col md={6}>
          <h4>Customer</h4>
          <ListGroup>
            <ListGroup.Item style={{ fontSize: "14px" }}>
              {customer === "dod-network"
                ? "DOD Network Map"
                : customer === "doe-network"
                ? "DOE Network Map"
                : customer === "dhs-network"
                ? "DHS Network Map"
                : "NASA Network Map"}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CustomerDetails;
